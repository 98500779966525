import { useMediaQuery } from "@react-hook/media-query";
import { flatMap } from "lodash";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { animated, useSpringRef, useTransition } from "react-spring";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";
import { getAssetURL } from "../../helpers/assets";
import {
  rem,
  toPixelsFromVW,
  toREM,
  toVWDesktop,
  toVWMobile,
  vwDesktop,
  vwMobile,
} from "../../helpers/styles";
import { BREAKPOINTS, MEDIA, MEDIA_DESKTOP } from "../../helpers/theme";
import { Image } from "../../types";
import Button, { ButtonType } from "../shared/ButtonLink";
import ImagesPreloader from "../shared/ImagesPreloader";
import Select from "../shared/Select";
import Carousel from "./Carousel";
import CarouselMobile from "./CarouselMobile";
import { useIntl } from "../../hooks/useIntl";
import { AccordionDetails } from "./AccordionDetails";
import { AvailableContent, DetailSection } from "./types";

export type ProductDetailInfoProps = {
  productSlug: string;
  product_list: DetailSection[];
  selectedVariantId?: string;
};

const defaultSelected = {
  benefitImages: [],
  badgesDisclaimer: "",
  ingredientsTitle: "",
  description: "",
  factsTitle: "",
  facts: "",
  factsTable: [],
  caption: "",
  instructionsTitle: "",
  instructions: [],
  title_1: "",
  subtitle: "",
  available_quantities_label: "",
  available_quantities_list: [],
};


const ProductDetail: FC<ProductDetailInfoProps> = ({
  productSlug,
  product_list,
  selectedVariantId,
}: ProductDetailInfoProps) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [selectedVariant, setSelectedVariant] =
    useState<DetailSection>(defaultSelected);
  const [selectedQuantity, setSelectedQuantity] =
    useState<AvailableContent | null>(null);
  const [newIngredientsDetail, setnewIngredientsDetail] = useState<string>();
  const containerRef = useRef<HTMLElement>(null);
  const heroItemRef = useRef<HTMLDivElement>(null);
  const desktopMatches = useRef(false);
  const mediaMatches = useMediaQuery(MEDIA.DESKTOP);
  desktopMatches.current = mediaMatches;

  const [currentImage, setCurrentImage] = useState<Image>();

  const [variantSlugParam] = useQueryParam("variant", StringParam);

  const internalLocale = useIntl();
  const [locale, setLocale] = useState("");

  const theNavBar: React.MutableRefObject<HTMLElement | null> =
    useRef<HTMLElement>(null);

  const transRef = useSpringRef();

  const CONTAINER_ID = "destini-container";

  const imgTransition = useTransition(currentImage, {
    ref: transRef,
    keys: currentImage,
    enter: {
      from: {
        translateY: "-20%",
        translateX: "3%",
        opacity: 0,
        scale: 0.9,
      },
      translateY: "0%",
      translateX: "0%",
      opacity: 1,
      scale: 1,
      delay: 250,
    },
    leave: {
      from: {
        translateX: "0%",
        translateY: "0%",
        opacity: 1,
        scale: 1,
      },
      translateX: "8%",
      translateY: "25%",
      opacity: 0,
      scale: 0.8,
    },
  });

  const setDefaultVariant = useCallback(() => {
    const defaultVariant = product_list?.find(
      (product) => product?.main_product
    ) ?? product_list?.[0];

    if (defaultVariant) {
      setSelectedVariant(defaultVariant);
      defaultVariant?.available_quantities_list?.length &&
        setSelectedQuantity(defaultVariant?.available_quantities_list[0]);
      const defaultVariantIndex = product_list?.indexOf(defaultVariant);
      setActiveSlide(defaultVariantIndex);
    }
  }, []);

  useEffect(() => {
    if (selectedVariantId || variantSlugParam) {
      const defaultVariant = product_list?.find(
        (product) => product?.product_variant_id === selectedVariantId || product?.product_variant_slug === variantSlugParam
      );

      if (defaultVariant) {
        setSelectedVariant(defaultVariant);
        const defaultVariantIndex = product_list?.indexOf(defaultVariant);
        setActiveSlide(defaultVariantIndex);
      } else {
        setDefaultVariant();
      }
    } else {
      setDefaultVariant();
    }
  }, [product_list]);

  const changeSlide = useCallback(
    (index: number) => {
      setSelectedVariant(product_list[index]);
      setActiveSlide(index);
    },
    [product_list, activeSlide]
  );

  const onScroll = useRef(() => {
    const container = containerRef.current;
    const heroItem = heroItemRef.current;
    const navHeight = theNavBar.current?.offsetHeight ?? 0;

    if (!(container && heroItem && desktopMatches.current)) return;

    if (
      window.scrollY >=
      container.getBoundingClientRect().height -
        heroItem.offsetHeight -
        toPixelsFromVW(toVWDesktop(91)) -
        toPixelsFromVW(toVWDesktop(80 + 56))
    ) {
      heroItem.style.position = "absolute";
      heroItem.style.top = `${
        container.getBoundingClientRect().height -
        heroItem.offsetHeight -
        toPixelsFromVW(toVWDesktop(91)) -
        toPixelsFromVW(toVWDesktop(80 + 56)) +
        navHeight
      }px`;
    } else {
      heroItem.style.position = "fixed";
      heroItem.style.top = navHeight + "px";
    }
  }).current;

  useEffect(() => {
    if (desktopMatches.current) {
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, []);

  useEffect(() => {
    const heroItem = heroItemRef.current;
    if (!desktopMatches) {
      if (heroItem) heroItem.style.position = "static";
    }
  }, [desktopMatches, heroItemRef.current]);

  const handleChangeQuantity = useCallback(
    (_option, indexQ) => {
      setSelectedQuantity(selectedVariant?.available_quantities_list[indexQ]);
    },
    [selectedVariant, setSelectedQuantity]
  );

  useEffect(() => {
    const firstQuantity = selectedVariant?.available_quantities_list?.[0];
    setSelectedQuantity(firstQuantity);
  }, [selectedVariant]);

  useEffect(() => {
    setCurrentImage({
      src: getAssetURL(selectedQuantity?.image_1) ?? "",
      alt: selectedQuantity?.image_1_alt ?? "",
    });
  }, [selectedQuantity]);

  useEffect(() => {
    theNavBar.current = document.getElementById("theNavBar");
  }, []);

  useEffect(() => {
    setLocale(internalLocale.locale);
  }, []);

  useEffect(() => {
    const replacedString = selectedVariant?.ingredientsDetail?.replace(
      /<p>\*/g,
      '<p class="disclaimer">*'
    );
    setnewIngredientsDetail(replacedString);
  }, [selectedVariant.ingredientsDetail]);

  const loadScript = () => {
    const script = document.createElement("script");
    if (
      internalLocale.locale === "en-GB" ||
      internalLocale.locale === "en-US" ||
      internalLocale.locale === "en-CA" ||
      internalLocale.locale === "fr-CA"
    ) {
      script.src =
        "https://lets.shop/productWidgetSnippet.js?id=destini-locator";
      script.setAttribute("charset", "utf-8");
    }

    const element = document.getElementById(CONTAINER_ID);
    if (element) {
      element.appendChild(script);
    }
  };

  useEffect(() => {
    loadScript();
  }, []);

  onScroll();

  const isCanada = internalLocale.locale === "en-CA" || internalLocale.locale === "fr-CA";

  return (
    <Container id={CONTAINER_ID} ref={containerRef}>
      <ImagesPreloader
        images={flatMap(
          product_list?.map((product) => {
            return product?.available_quantities_list?.map(
              (quantity) => getAssetURL(quantity?.image_1) ?? ""
            );
          })
        )}
      />
      <Hero>
        <CarouselMobile
          onSlideChange={changeSlide}
          slides={product_list}
          productSlug={productSlug}
          />
        <HeroSell>
          {selectedVariant?.title_1 && (
            <Title
              dangerouslySetInnerHTML={{ __html: selectedVariant?.title_1 }}
            />
          )}
          {selectedVariant?.subtitle && (
            <Subtitle>
              <span>{selectedVariant?.subtitle}</span>
            </Subtitle>
          )}
          <Description
            dangerouslySetInnerHTML={{ __html: selectedVariant?.description || "" }}
          />
        </HeroSell>
        <HeroItem ref={heroItemRef}>
          <Preview aria-roledescription="carousel">
            {imgTransition((styles, image) => {
              if (!image?.src) {
                return null;
              }
              return (
                <animated.img
                  aria-hidden={image?.src !== currentImage?.src}
                  aria-roledescription="slide"
                  aria-label={image?.alt}
                  style={styles}
                  src={image?.src}
                  alt={image?.alt}
                />
              );
            })}
          </Preview>
          <Actions>
            <ActionSelect
              className={
                selectedVariant?.available_quantities_list?.length > 0
                  ? ""
                  : "hide"
              }
            >
              <label htmlFor={"variant-dropdown"}>
                {selectedVariant?.available_quantities_label}
              </label>
              {!!selectedVariant?.available_quantities_list?.length && (
                <Select
                  id={"variant-dropdown"}
                  options={selectedVariant?.available_quantities_list?.map(
                    (content) => ({
                      label: `${content?.quantity}`,
                      value: content?.quantity,
                    })
                  )}
                  onChange={handleChangeQuantity}
                  value={selectedQuantity?.quantity ?? ""}
                />
              )}
            </ActionSelect>

            <ActionButtons>
              {selectedQuantity?.left_button_link &&
                (locale === "en-GB" ? (
                  <div
                    APO={selectedQuantity?.left_button_link}
                    id="destini-locator"
                    className="destini-locator-class"
                    locator-id="3030"
                    alpha-code="BD6"
                    locator-name="BeyondMeat UK OCL"
                    client-id="beyondmeatuk"
                    destini-header-name=""
                    destini-header-image=""
                  ></div>
                ) : locale === "en-US" ? (
                  <div
                    APO={selectedQuantity?.left_button_link}
                    id="destini-locator"
                    className="destini-locator-class"
                    locator-id="2506"
                    alpha-code="9CA"
                    locator-name="Beyond Meat Product Description Page Widget"
                    client-id="beyondmeat"
                    destini-header-name=""
                    destini-header-image=""
                  ></div>
                ) : locale === "en-CA" || locale === "fr-CA" ? (
                  <div
                    APO={selectedQuantity?.left_button_link}
                    id="destini-locator"
                    className="destini-locator-class"
                    locator-id="2507"
                    alpha-code="9CB"
                    locator-name="Beyond Meat Canada OCL Product Widget"
                    client-id="beyondmeatcanada"
                    destini-header-name=""
                    destini-header-image=""
                  ></div>
                ) : (
                  <Button
                    label={selectedQuantity?.left_button_label}
                    link={selectedQuantity?.left_button_link}
                    type={ButtonType.PRIMARY_LIGHT}
                  />
                ))}
              {selectedQuantity?.right_button_link && (
                <Button
                  label={selectedQuantity?.right_button_label}
                  link={selectedQuantity?.right_button_link}
                  type={ButtonType.SECONDARY_LIGHT}
                />
              )}
            </ActionButtons>
          </Actions>
          {product_list?.length > 1 && (
            <Carousel
              active={activeSlide}
              productSlug={productSlug}
              slides={product_list}
            />
          )}
        </HeroItem>
      </Hero>

      <InfoContainer>
        {isCanada && (
          <>
            <SectionTitle>{selectedVariant?.ingredientsTitle}</SectionTitle>
            <Description
              dangerouslySetInnerHTML={{
                __html: newIngredientsDetail || "",
              }}
            />
            <AccordionDetails
              accordionHeadline={selectedVariant.accordionHeadline}
              accordionSubheadline={selectedVariant.accordionSubheadline}
              accordionItems={selectedVariant.accordionItems}  
            />
          </>
        )}
        <BadgeContainer>
          {selectedVariant?.benefitImages?.map((benefit, index) => (
            <Badge key={index} src={benefit?.src} alt={benefit?.alt} />
          ))}
        </BadgeContainer>
        {selectedVariant?.badgesDisclaimer && (
          <BadgesDisclaimer
            dangerouslySetInnerHTML={{
              __html: selectedVariant?.badgesDisclaimer,
            }}
          />
        )}
        {!isCanada && (
          <>
            <SectionTitle>{selectedVariant?.ingredientsTitle}</SectionTitle>
            <Description
              dangerouslySetInnerHTML={{
                __html: newIngredientsDetail || "",
              }}
            />
            <AccordionDetails
              accordionHeadline={selectedVariant.accordionHeadline}
              accordionSubheadline={selectedVariant.accordionSubheadline}
              accordionItems={selectedVariant.accordionItems}  
            />
          </>
        )}
        <SectionTitle>{selectedVariant?.factsTitle}</SectionTitle>
        <FactWrapper
          dangerouslySetInnerHTML={{
            __html: selectedVariant?.facts || "",
          }}
        />
        <Table>
          <TableRow>
            {selectedVariant?.factsTable?.header?.map((header, index) => {
              if (index === 0) {
                return <TableData key={index} />;
              }
              return (
                <TableHeader
                  aria-label={header}
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: header ?? "",
                  }}
                />
              );
            })}
          </TableRow>
          {selectedVariant?.factsTable?.body?.map((row, index) => (
            <TableRow key={index}>
              <TableDataLabel
                dangerouslySetInnerHTML={{
                  __html: row?.label ?? "",
                }}
              />
              <TableData
                dangerouslySetInnerHTML={{
                  __html: row?.amount ?? "",
                }}
              />
              <TableData
                dangerouslySetInnerHTML={{
                  __html: row?.percentage ?? "",
                }}
              />
            </TableRow>
          ))}
        </Table>
        <Description className="nutrition">
          {selectedVariant?.caption}
        </Description>
        <SectionTitle>{selectedVariant?.instructionsTitle}</SectionTitle>
        {selectedVariant?.instructions?.map((instruction, index) => (
          <InstructionCard key={index}>
            <InstructionLogoWrapper>
              {instruction?.logos?.map((logo, indexLogo) => (
                <IntructionImage
                  key={indexLogo}
                  src={logo?.src}
                  alt={logo?.alt}
                />
              ))}
            </InstructionLogoWrapper>
            <InstructionTitle>{instruction.title}</InstructionTitle>
            <Description
              className="instruction"
              dangerouslySetInnerHTML={{ __html: instruction?.description || "" }}
            />
          </InstructionCard>
        ))}
      </InfoContainer>
    </Container>
  );
};

const Hero = styled.div`
  padding: ${vwMobile(40)} 0;
  font-size: ${rem(18)};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  background-color: #012102;
  color: white;

  ${MEDIA_DESKTOP} {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: ${vwDesktop(100)} 0 ${vwDesktop(50)};
    background-color: transparent;
  }
`;

const HeroSell = styled.div`
  padding: 0 ${vwMobile(25)};
  ${MEDIA_DESKTOP} {
    padding-left: ${vwDesktop(205)};

    & * {
      text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
    }
  }
`;

const HeroItem = styled.div`
  padding: 0 ${vwMobile(25)};
  position: static;

  ${MEDIA_DESKTOP} {
    position: fixed;
    right: 0;
    width: 50%;
    z-index: 8;
    background-color: #012102;
    padding: ${vwDesktop(50)} ${vwDesktop(40)};
    height: ${vwDesktop(700)};
  }
`;

const Preview = styled.div`
  display: none;
  ${MEDIA_DESKTOP} {
    position: relative;
    display: grid; // to stack the images
    grid-template: auto / auto;
    margin-bottom: ${vwDesktop(88)};
    width: ${vwDesktop(486)};
    height: ${vwDesktop(362)};
    transform: translateX(${vwDesktop(-70)});
    img {
      grid-area: 1 / 1;
      width: ${vwDesktop(486)};
      height: ${vwDesktop(362)};
      object-fit: cover;
    }
  }

  @media (min-width: 2000px) {
    margin-bottom: ${vwDesktop(44)};
  } ;
`;

const Actions = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${vwMobile(41)};
  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(41)};
  }
`;

const ActionSelect = styled.div`
  display: flex;
  direction: ${(props) => (props.theme.isRTL ? "rtl" : "ltr")};
  flex-flow: column;
  gap: ${vwMobile(17)};
  ${MEDIA_DESKTOP} {
    flex-flow: row;
    align-items: center;
    gap: ${vwDesktop(19)};
  }

  label {
    font-size: ${rem(18)};
    font-weight: 900;
    letter-spacing: 0.02em;
    line-height: ${rem(24)};
    ${MEDIA_DESKTOP} {
      font-weight: 800;
      font-size: ${rem(24)};
      line-height: ${rem(26)};
    }
  }

  &.hide {
    visibility: hidden;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${vwMobile(16)};
  ${MEDIA_DESKTOP} {
    flex-flow: row;
    gap: ${vwDesktop(20)};
    > * {
      flex: 0 1 ${vwDesktop(180)};
    }
  }
`;

const Title = styled.h1`
  color: white;
  font-size: ${rem(48)};
  line-height: ${rem(52)};
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.03em;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;

  ${MEDIA_DESKTOP} {
    font-size: ${rem(80)};
    line-height: ${rem(80)};
  }

  & p {
    display: flex;
    flex-direction: column;
  }

  span,
  strong {
    color: #75c154;
    font-weight: inherit;
  }
`;

const Subtitle = styled.h2`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.03em;
  line-height: ${rem(52)};
  margin-block: ${vwMobile(4)};
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;
  position: relative;

  ${MEDIA_DESKTOP} {
    font-size: ${toREM(48)}rem;
    line-height: ${rem(50)};
    margin: ${vwDesktop(8)} 0 ${vwDesktop(20)};
  }

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;

  span {
    grid-area: 1/1;
    transition: all 0.5s;
  }
`;
const Container = styled.section`
  background-color: #033305;
  direction: ltr;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-bottom: ${toVWDesktop(200)}vw;
  }
`;
const InfoContainer = styled.section`
  align-items: flex-start;
  direction: ${(props) => (props.theme.isRTL ? "rtl" : "ltr")};
  display: flex;
  flex-direction: column;
  padding: ${toVWMobile(25)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${vwDesktop(483)};
    padding: ${toVWDesktop(0)}vw 0 ${toVWDesktop(30)}vw;
    padding-right: ${vwDesktop(80)};
    margin-left: ${vwDesktop(205)};
  }
`;

const BadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: ${toVWMobile(10)}vw;
  gap: ${vwMobile(20)};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    justify-content: flex-start;
    width: ${vwDesktop(450)};
    gap: ${vwDesktop(20)};
    padding: 0;
  }
`;

const Badge = styled.img`
  width: auto;
  height: ${toVWMobile(118)}vw;
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: auto;
    height: ${toVWDesktop(110)}vw;
  }
`;

const SectionTitle = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.03em;
  line-height: ${toREM(52)}rem;
  margin: ${toVWMobile(40)}vw 0 ${toVWMobile(28)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;
  word-break: break-word;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(48)}rem;
    line-height: ${toREM(60)}vw;
    margin: ${toVWDesktop(57)}vw 0 ${toVWDesktop(44)}vw;
    &:first-child {
      margin-top: 0px;
    }
  }
`;

const Description = styled.p`
  color: #ffffff;
  direction: ${(props) => (props.theme.isRTL ? "rtl" : "ltr")};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0;
  line-height: ${rem(28)};
  margin: 0 0 ${toVWMobile(60)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin: 0 0 ${toVWDesktop(60)}vw;
  }

  & ul {
    padding: 0 ${toVWMobile(20)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      padding: 0 ${toVWDesktop(25)}vw;
    }
  }

  &.nutrition {
    font-size: ${toREM(12)}rem;
    line-height: ${toREM(18)}rem;
    margin: ${toVWMobile(25)}vw 0 ${toVWMobile(30)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin: ${toVWDesktop(25)}vw 0 ${toVWDesktop(30)}vw;
    }
  }

  &.instruction {
    margin-bottom: ${toVWMobile(42)}vw;
    direction: ${(props) => (props.theme.isRTL ? "rtl" : "ltr")};

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${toVWDesktop(42)}vw;
    }
  }

  .disclaimer {
    font-size: ${toREM(12)}rem;
    line-height: ${toREM(18)}rem;
  }
`;

const BadgesDisclaimer = styled.p`
  color: #ffffff;
  direction: ${(props) => (props.theme.isRTL ? "rtl" : "ltr")};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(12)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0;
  line-height: ${toREM(18)}rem;
  padding: ${toVWMobile(5)}vw 0;
`;

const FactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(28)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(28)}vw;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  width: 100%;
`;

const TableRow = styled.tr`
  color: #ffffff;
  border-bottom: ${toVWMobile(1)}vw solid #3a6249;
`;

const TableHeader = styled.th`
  font-size: ${toREM(14)}rem;
  font-weight: 600;
  line-height: ${toREM(18)}rem;
  text-align: ${(props) => (props.theme.isRTL ? "left" : "right")};
  padding: ${toVWMobile(15)}vw 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(15)}vw 0;
  }
`;

const TableData = styled.td`
  text-align: ${(props) => (props.theme.isRTL ? "left" : "right")};
  font-size: ${toREM(18)}rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.01em;
  padding: ${toVWMobile(15)}vw 0;

  strong, em {
    font-weight: 800;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(15)}vw 0;
  }
`;

const TableDataLabel = styled(TableData)`
  font-weight: 800;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  min-width: ${toVWMobile(120)}vw;
  max-width: ${toVWMobile(120)}vw;

  strong, em {
    font-weight: 900;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    min-width: ${toVWDesktop(120)}vw;
    max-width: ${toVWDesktop(120)}vw;
  }
`;

const InstructionCard = styled.div``;

const InstructionLogoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${vwMobile(20)};
  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(20)};
  }
`;

const IntructionImage = styled.img`
  height: ${toVWMobile(72)}vw;
  width: ${toVWMobile(72)}vw;
  margin-bottom: ${toVWMobile(26)}vw;
  margin-right: ${toVWMobile(24)};
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(72)}vw;
    width: ${toVWDesktop(72)}vw;
    margin-bottom: ${toVWDesktop(26)}vw;
    margin-right: ${toVWDesktop(24)};
  }
`;

const InstructionTitle = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.03em;
  line-height: ${toREM(40)}rem;
  margin-bottom: ${toVWMobile(12)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(36)}rem;
    line-height: ${toREM(45)}rem;
    margin-bottom: ${toVWDesktop(10)}vw;
  }
`;

export default ProductDetail;
